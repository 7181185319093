import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BuyedpackageStatus, GroupType } from 'src/app/core/enums/enums';
import { LanguageService } from 'src/app/core/services/language.service';
import { IRole, IUser } from 'src/app/core/models/User';
import { ServicesService } from 'src/app/core/services/services.service';
import {
  UseCaseGroupService,
  UseCaseHcpService,
} from 'src/app/core/rest-services/rest-services';
import { Subscription } from 'rxjs';
import { ICampaign } from 'src/app/core/models/Campaign';
import { FaqComponent } from '../faq/faq.component';
import { parseTokNotion } from 'src/app/core/utils/misc';
import { AdminMonitoringService } from 'src/app/core/rest-services/usecases/admin-monitoring.service';
import { CountRecords } from 'src/app/core/models/AdminMonitoring';

interface NavItem {
  name: string;
  mobileName: string;
  url: string;
  icon: string;
  iconLabel: string;
  shape: string;
}

@Component({
  selector: 'app-layout-navigation',
  templateUrl: './layout-navigation.component.html',
  styleUrls: ['./layout-navigation.component.scss'],
})
export class LayoutNavigationComponent implements OnInit, OnDestroy {
  @Input() breakpointMatch = true;
  @Input() isLoading = false;
  @Input() currentCampaign!: ICampaign | undefined;
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  translations: any = {
    navDashboardLink: '',
    navPatientLink: '',
    giveHealthCredits: '',
    commonProfileLink: '',
    navFaqLink: '',
    faqLinkUrl: '',
    logoutButton: '',
    navMyImpactLink: '',
    navHealthProvidersLink: '',
    navIncommingPatient: '',
    navPendingPatient: '',
    navPatientsEnd: '',
    navUsersLink: '',
    navHealthcareProvidersLink: '',
    navHealthcareModulesLink: '',
    navMedicalServicesLink: '',
    navExchangeRatesLink: '',
    patientAssignationLabel: '',
    campaign: '',
    navGroupLink: '',
    navMyCampaignsLink: '',
    navInvoicesLink: '',
    navSettingsLink: '',
    navmMyImpactLink: '',
    navmHealthProvidersLink: '',
    navmIncommingPatient: '',
    navmPendingPatient: '',
    navmPatientsEnd: '',
    navmDashboardLink: '',
    navmUsersLink: '',
    navmHealthcareProvidersLink: '',
    navmHealthcareModulesLink: '',
    navmPatientLink: '',
    givemHealthCredits: '',
    myTreatmentLink: '',
    buyePackageLink: '',
    discountCardLink: '',
    navAdminDashboardLink: '',
    navBenefitDashboardLink: '',
    navBenefitEmployeesLink: '',
    navBenefitPackagesLink: '',
    navBenefitHcpLink: '',
  };
  translationKeys: string[] = [
    'kimbo-layout.nav-dashboard-link',
    'kimbo-layout.nav-patient-link',
    'common.give-health-credits',
    'common.profile-link',
    'kimbo-layout.nav-faq-link',
    'common.faq-link-url',
    'common.logout-button',
    'hcp-layout.nav-incomming-patient',
    'hcp-layout.nav-pending-patient',
    'hcp-layout.nav-patients-end',
    'hcp-layout.nav-history-link',
    'kimbo-layout.nav-my-impact-link',
    'kimbo-layout.nav-health-providers-link',
    'admin-layout.nav-users-link',
    'admin-layout.nav-healthcare-providers-link',
    'admin-layout.nav-healthcare-modules-link',
    'admin-layout.nav-medical-services-link',
    'admin-layout.nav-transactions-link',
    'admin-layout.nav-exchange-rates-link',
    'admin-layout.buyedpackage-label',
    'hcp-home.patient_assignation-label',
    'corporate-campaign.campaign',
    'admin-layout.nav-group-link',
    'kimbo-layout.nav-my-campaigns-link',
    'admin-layout.nav-invoices-link',
    'admin-settings.page-heading',
    'hcp-layout.nav-m-incomming-patient',
    'hcp-layout.nav-m-pending-patient',
    'hcp-layout.nav-m-patients-end',
    'hcp-layout.nav-m-dashboard',
    'hcp-home.patient-m-assignation-label',
    'kimbo-layout.nav-m-patient-link',
    'kimbo-layout.nav-m-my-impact-link',
    'kimbo-layout.nav-m-health-providers-link',
    'common.give-m-health-credits',
    'kimbo-home.treatments-title',
    'admin-layout.nav-discount-cards-link',
    'admin-layout.nav-dashboard-link',
    'benefit-layout.dashboard-label',
    'benefit-layout.employees-label',
    'benefit-layout.healthcare-module-label',
    'benefit-layout.healthcare-providers-label',
  ];
  currentRole!: IRole;
  groupType!: GroupType;
  role = GroupType;
  navItems!: NavItem[];
  $languageChange!: Subscription;
  countResponse: any;
  incomming_buyedPackages!: number;
  treatment_buyedPackages!: number;
  finished_buyedPackages!: string;
  count_users!: any;
  unuseBuyedPackages!: number;
  $patientRefresh!: Subscription;
  $usersCountRefresh!: Subscription;
  $rolechange!: Subscription;
  $contactCount!: Subscription;
  isCSnackOpen = false;
  count_records!: CountRecords;
  $loginEnd!: Subscription;
  contacts = 0;
  isWhiteLabel = false;

  constructor(
    public services: ServicesService,
    private languageService: LanguageService,
    private useCaseHcpService: UseCaseHcpService,
    private adminMonitoringService: AdminMonitoringService,
    private usecaseGroupService: UseCaseGroupService
  ) {
    this.isWhiteLabel = !!this.services.sessionService.getWhiteLabel();
  }

  ngOnDestroy(): void {
    this.$languageChange && this.$languageChange.unsubscribe();
    if (this.$patientRefresh) this.$patientRefresh.unsubscribe();
    if (this.$rolechange) this.$rolechange.unsubscribe();
    if (this.$loginEnd) this.$loginEnd.unsubscribe();
    if (this.$usersCountRefresh) this.$usersCountRefresh.unsubscribe();
    if (this.$contactCount) this.$contactCount.unsubscribe();
  }

  ngOnInit(): void {
    this.getTranslations();
    this.listenForLanguageChangeEvents();
    this.listenForRoleChangeEvents();
    this.currentRole = this.services.roleService.getCurrentRole();
    this.groupType = this.services.roleService.getCurrentTypeRole();

    //PATIENT COUNTER REFRESH
    if (
      this.services.sessionService.hasRole(this.role.Hcp) &&
      this.services.sessionService.getCurrentRole().group.type === GroupType.Hcp
    ) {
      const hcpRole: IRole = this.services.sessionService.getCurrentRole();
      if (hcpRole.group.healthcareprovider != null) {
        this.fetchBuyedsPackageCount(hcpRole.group.healthcareprovider.id);
      }
    }

    this.$patientRefresh = this.services.eventService.subscribe(
      'hcp-patient-count:refresh',
      () => {
        if (
          this.services.sessionService.hasRole(this.role.Hcp) &&
          this.services.sessionService.getCurrentRole().group.type ===
            GroupType.Hcp
        ) {
          const hcpRole: IRole = this.services.sessionService.getCurrentRole();
          if (hcpRole.group.healthcareprovider != null) {
            this.fetchBuyedsPackageCount(hcpRole.group.healthcareprovider.id);
          }
        }
      }
    );

    //GLOBAL LOGIN END
    this.$loginEnd = this.services.eventService.subscribe('login:end', () => {
      this.isWhiteLabel = !!this.services.sessionService.getWhiteLabel();
      this.getNavItems();
    });

    //ADMIN COUNTER REFRESH
    if (
      this.services.sessionService.getCurrentRole().group.type ===
      this.role.Admin
    ) {
      this.fetchResourcesCount();
    }

    this.$usersCountRefresh = this.services.eventService.subscribe(
      'admin-count:refresh',
      () => {
        if (
          this.services.sessionService.getCurrentRole().group.type ===
          this.role.Admin
        ) {
          this.fetchResourcesCount();
        }
      }
    );

    //BENEFICIARIES COUNTER REFRESH
    if (
      this.services.sessionService.getCurrentRole().group.type ===
        this.role.Kimbo ||
      this.services.sessionService.getCurrentRole().group.type ===
        this.role.Benefit
    ) {
      this.fetchContactsCount();
    }

    this.$contactCount = this.services.eventService.subscribe(
      'contact-count:refresh',
      () => {
        if (
          this.services.sessionService.getCurrentRole().group.type ===
            this.role.Kimbo ||
          this.services.sessionService.getCurrentRole().group.type ===
            this.role.Benefit
        ) {
          this.fetchContactsCount();
        }
      }
    );
    this.navItems = this.getNavItems();
  }

  public getNavItems(): NavItem[] {
    const navItems: NavItem[] = [];
    if (this.groupType === GroupType.Kimbo) {
      navItems.push({
        name: this.translations.navHealthProvidersLink,
        mobileName: this.translations.navmHealthProvidersLink,
        url: '/kimbo',
        icon: 'assets/svg/general-medicine-white.svg',
        iconLabel: 'Tableau de bord icon',
        shape: '_COMMON',
      });

      navItems.push({
        name: this.translations.giveHealthCredits,
        mobileName: this.translations.givemHealthCredits,
        url: '/kimbo/health-bundles',
        icon: 'assets/svg/modules.svg',
        iconLabel: 'Patients icon',
        shape: '_COMMON',
      });

      navItems.push({
        name: this.translations.navPatientLink,
        mobileName: this.translations.navPatientLink,
        url: '/kimbo/my-beneficiary',
        icon: 'assets/svg/people-white.svg',
        iconLabel: 'Tableau de bord icon',
        shape: '_COMMON',
      });

      if (!this.services.sessionService.isGuest() && !this.isWhiteLabel) {
        navItems.push({
          name: this.translations.myTreatmentLink,
          mobileName: this.translations.myTreatmentLink,
          url: '/kimbo/my-treatments',
          icon: 'assets/svg/patients.svg',
          iconLabel: 'Tableau de bord icon',
          shape: '_COMMON',
        });
        navItems.push({
          name: this.translations.navMyImpactLink,
          url: '/kimbo/my-impact',
          icon: 'assets/svg/statistics.svg',
          iconLabel: 'statistics icon',
          shape: '_COMMON',
          mobileName: this.translations.navmMyImpactLink,
        });
      }

      return this.breakpointMatch
        ? navItems
        : navItems.filter(
            navItem =>
              navItem.shape === '_COMMON' &&
              navItem.url !== '/kimbo/my-impact' &&
              navItem.url !== '/kimbo/my-treatments'
          );
    }
    if (this.groupType === GroupType.Benefit) {
      navItems.push({
        name: this.translations.navBenefitDashboardLink,
        mobileName: this.translations.navBenefitDashboardLink,
        url: '/benefit',
        icon: 'assets/svg/statistics.svg',
        iconLabel: 'Tableau de bord icon',
        shape: '_COMMON',
      });
      navItems.push({
        name: this.translations.navBenefitEmployeesLink,
        mobileName: this.translations.navBenefitEmployeesLink,
        url: '/benefit/employees',
        icon: 'assets/svg/user-s.svg',
        iconLabel: 'User icon',
        shape: '_COMMON',
      });
      navItems.push({
        name: this.translations.navBenefitPackagesLink,
        mobileName: this.translations.navBenefitPackagesLink,
        url: '/benefit/health-bundles',
        icon: 'assets/svg/modules.svg',
        iconLabel: 'Modules icon',
        shape: '_COMMON',
      });
      navItems.push({
        name: this.translations.navBenefitHcpLink,
        mobileName: this.translations.navBenefitHcpLink,
        url: '/benefit/healthcare-providers',
        icon: 'assets/svg/general-medicine-white.svg',
        iconLabel: 'Tableau de bord icon',
        shape: '_COMMON',
      });

      return this.breakpointMatch
        ? navItems
        : navItems.filter(
            navItem =>
              navItem.shape === '_COMMON' &&
              navItem.url !== '/benefit/healthcare-providers'
          );
    } else if (this.groupType == GroupType.Hcp) {
      navItems.push({
        name: this.translations.navIncommingPatient,
        url: '/hcp',
        icon: 'assets/svg/people-white.svg',
        iconLabel: 'Patient incoming icon',
        shape: '_COMMON',
        mobileName: this.translations.navmIncommingPatient,
      });
      navItems.push({
        name: this.translations.navPendingPatient,
        url: '/hcp/patient-in-treatment',
        icon: 'assets/svg/patients.svg',
        iconLabel: 'Patients treatment icon',
        shape: '_COMMON',
        mobileName: this.translations.navmPendingPatient,
      });
      navItems.push({
        name: this.translations.navPatientsEnd,
        url: '/hcp/patient-treated',
        icon: 'assets/svg/history.svg',
        iconLabel: 'Patients end icon',
        shape: '_COMMON',
        mobileName: this.translations.navmPatientsEnd,
      });
      navItems.push({
        name: this.translations.patientAssignationLabel,
        url: '/hcp/campaigns',
        icon: 'assets/svg/people-white.svg',
        iconLabel: '#',
        shape: '_COMMON',
        mobileName: this.translations.patientmAssignationLabel,
      });
      /*      navItems.push({
              name: this.translations.navmDashboardLink,
              url: '/hcp/dashboard-hcp',
              icon: 'assets/svg/statistics.svg',
              iconLabel: '#',
              shape: '_COMMON',
              mobileName: '',
            });
      */
      return this.breakpointMatch
        ? navItems
        : navItems.filter(navItem => navItem.url !== '/hcp/dashboard-hcp');
    }
    return this.breakpointMatch
      ? navItems
      : navItems.filter(navItem => navItem.shape === '_COMMON');
  }

  fetchBuyedsPackageCount(hcpId: number): void {
    this.isLoading = true;
    this.useCaseHcpService.listCountBuyedPackage(hcpId).subscribe({
      next: res => {
        this.incomming_buyedPackages = 0;
        this.treatment_buyedPackages = 0;
        this.finished_buyedPackages = '0';
        this.unuseBuyedPackages = 0;
        res.counter.forEach((bd: any) => {
          if (bd.status === BuyedpackageStatus.Ready) {
            this.incomming_buyedPackages = bd.count;
          } else if (bd.status === BuyedpackageStatus.InUse) {
            this.treatment_buyedPackages = bd.count;
          } else if (bd.status === BuyedpackageStatus.Finished) {
            this.finished_buyedPackages = parseTokNotion(bd.count);
          }
        });
        this.unuseBuyedPackages = res.unuse.length;
      },
      error: () => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  fetchResourcesCount() {
    this.isLoading = true;
    this.adminMonitoringService.countRecords().subscribe({
      next: (res: CountRecords) => {
        this.count_records = res;
      },
      error: () => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  fetchContactsCount() {
    this.isLoading = true;
    this.usecaseGroupService
      .countContacts(this.services.sessionService.getCurrentRole().group.id)
      .subscribe({
        next: (res: any) => {
          this.contacts = res.contact;
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  listenForLanguageChangeEvents(): void {
    this.$languageChange = this.services.eventService.subscribe(
      'change:language',
      () => {
        this.getTranslations();
      }
    );
  }

  public listenForRoleChangeEvents(): void {
    this.$rolechange = this.services.eventService.subscribe(
      'change:role',
      () => {
        this.currentRole = this.services.roleService.getCurrentRole();
        this.groupType = this.services.roleService.getCurrentTypeRole();
        if (this.groupType === this.role.Hcp) {
          this.services.roleService.getCurrentHcpId();
          this.fetchBuyedsPackageCount(
            this.services.roleService.getCurrentHcpId()!
          );
        }

        if (this.groupType === GroupType.Admin) {
          this.fetchResourcesCount();
        }
        this.navItems = this.getNavItems();
      }
    );
  }

  openSideMenu(): void {
    this.services.eventService.publish('show:mobiledrawer');
  }

  openSupportModal(): void {
    this.services.modalService.openModal(FaqComponent, {
      width: '500px',
      height: 'auto',
    });
  }

  openCampainSnack(): void {
    if (this.currentCampaign) {
      this.services.eventService.publish('show:CSnack');
      this.isCSnackOpen = !this.isCSnackOpen;
    }
  }

  getTranslations(): void {
    this.languageService.get(this.translationKeys).subscribe(translations => {
      this.translations.navDashboardLink =
        translations['kimbo-layout.nav-dashboard-link'];
      this.translations.navPatientLink =
        translations['kimbo-layout.nav-patient-link'];
      this.translations.navmPatientLink =
        translations['kimbo-layout.nav-m-patient-link'];
      this.translations.giveHealthCredits =
        translations['common.give-health-credits'];
      this.translations.givemHealthCredits =
        translations['common.give-m-health-credits'];
      this.translations.commonProfileLink = translations['common.profile-link'];
      this.translations.commonProfileLink = translations['common.profile-link'];
      this.translations.navFaqLink = translations['kimbo-layout.nav-faq-link'];
      this.translations.faqLinkUrl = translations['common.faq-link-url'];
      this.translations.logoutButton = translations['common.logout-button'];
      this.translations.navIncommingPatient =
        translations['hcp-layout.nav-incomming-patient'];
      this.translations.navmIncommingPatient =
        translations['hcp-layout.nav-m-incomming-patient'];
      this.translations.navPendingPatient =
        translations['hcp-layout.nav-pending-patient'];
      this.translations.navmPendingPatient =
        translations['hcp-layout.nav-m-pending-patient'];
      this.translations.navPatientsEnd =
        translations['hcp-layout.nav-patients-end'];
      this.translations.navmPatientsEnd =
        translations['hcp-layout.nav-m-patients-end'];
      this.translations.navmDashboardLink =
        translations['hcp-layout.nav-m-dashboard'];
      this.translations.navMyImpactLink =
        translations['kimbo-layout.nav-my-impact-link'];
      this.translations.navmMyImpactLink =
        translations['kimbo-layout.nav-m-my-impact-link'];
      this.translations.navHealthProvidersLink =
        translations['kimbo-layout.nav-health-providers-link'];
      this.translations.navmHealthProvidersLink =
        translations['kimbo-layout.nav-m-health-providers-link'];
      this.translations.navUsersLink =
        translations['admin-layout.nav-users-link'];
      this.translations.navHealthcareProvidersLink =
        translations['admin-layout.nav-healthcare-providers-link'];
      this.translations.navHealthcareModulesLink =
        translations['admin-layout.nav-healthcare-modules-link'];
      this.translations.navMedicalServicesLink =
        translations['admin-layout.nav-medical-services-link'];
      this.translations.buyePackageLink =
        translations['admin-layout.buyedpackage-label'];
      this.translations.navTransactionsLink =
        translations['admin-layout.nav-transactions-link'];
      this.translations.navExchangeRatesLink =
        translations['admin-layout.nav-exchange-rates-link'];
      this.translations.navUsersLink =
        translations['admin-layout.nav-users-link'];
      this.translations.patientAssignationLabel =
        translations['hcp-home.patient_assignation-label'];
      this.translations.patientmAssignationLabel =
        translations['hcp-home.patient-m-assignation-label'];
      this.translations.campaign = translations['corporate-campaign.campaign'];
      this.translations.navGroupLink =
        translations['admin-layout.nav-group-link'];
      this.translations.navMyCampaignsLink =
        translations['kimbo-layout.nav-my-campaigns-link'];
      this.translations.navInvoicesLink =
        translations['admin-layout.nav-invoices-link'];
      this.translations.navSettingsLink =
        translations['admin-settings.page-heading'];
      this.translations.myTreatmentLink =
        translations['kimbo-home.treatments-title'];
      this.translations.discountCardLink =
        translations['admin-layout.nav-discount-cards-link'];
      this.translations.navAdminDashboardLink =
        translations['admin-layout.nav-dashboard-link'];

      this.translations.navBenefitDashboardLink =
        translations['benefit-layout.dashboard-label'];
      this.translations.navBenefitEmployeesLink =
        translations['benefit-layout.employees-label'];
      this.translations.navBenefitPackagesLink =
        translations['benefit-layout.healthcare-module-label'];
      this.translations.navBenefitHcpLink =
        translations['benefit-layout.healthcare-providers-label'];
      this.navItems = this.getNavItems();
    });
  }
}
