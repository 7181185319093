import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WhiteLabel, WhiteLabelForm } from '../../models/white-label';
import { Content } from '../../models/Common';

@Injectable({
  providedIn: 'root',
})
export class UcaseServiceWhiteLabelService {
  private baseUrl = `${environment.basePath}`;

  constructor(private http: HttpClient) {}

  /*
   * getWhiteLabels
   * @Param white label uuid
   */
  getWhiteLabels(label_uuid: string): Observable<WhiteLabel> {
    return this.http.get<WhiteLabel>(
      `${this.baseUrl}/v1/white-label/${label_uuid}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
}
