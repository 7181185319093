import { Content, Currency, Description, Title } from './Common';
import { ICountryModel } from './Country';
import { IHealthcarepackage } from './Healthcarepackage';
import { IFile, ImageProfil } from './ImageProfil';

export interface IHealthcareProvider {
  id: number;
  currencie_id: number;
  rating: number;
  group_id: number;
  data: Data;
  name: string;
  adresse: Adresse;
  type: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  custom_margin_kimbo?: number;
  custom_margin_corporate?: number;
  title: Title;
  healthcarepackages: IHealthcarepackage[];
  description: Description;
  image_profil: ImageProfil;
  images: ImageProfil[];
  currencie: Currency;
  speciality: string[];
  distance?: number;
  countrie_id?: number;
  active: boolean;
  legal_documents?: IFile[];
  payment_method?: any;
  tittle?: Title;
  country?: ICountryModel;
  can_purchase?: boolean;
  count_interest?: boolean;
  interest?: boolean;
}

interface Adresse {
  name?: string;
  street: string;
  postcode: string;
  city: string;
  state: string;
  country: string;
  contact: string;
  latitude: string;
  longitude: string;
}

export interface HealthcareProviderPageResponse {
  status_code: number;
  healthcareproviders: Content<IHealthcareProvider>;
}

export interface HealthcareProviderCommonResponse {
  healthcareprovider: IHealthcareProvider;
}

interface Data {
  name: string;
  location: string;
  rating: number;
  type: string;
  description: string;
  specialities: string;
  state: string;
  city: string;
  street: string;
  postcode: string;
  country: string;
  longitude: string;
  latitude: string;
  contact: string;
  count_view: number;
  hours_overture?: {
    monday?: string;
    tuesday?: string;
    wednesday?: string;
    thursday?: string;
    friday?: string;
    saturday?: string;
    sunday?: string;
  };
  urls: [];
}

export class HealcareProviderStore {
  name: string;
  type: string;
  rating: number;
  speciality: string[];
  currencie_id: number;
  group_id: number;
  countrie_id: number;
  data: any;
  adresse: any;

  constructor(
    name: string,
    type: string,
    rating: number,
    speciality: string[],
    currencie_id: number,
    group_id: number,
    data: any,
    adresse: any,
    countrie_id: number
  ) {
    this.type = type;
    this.rating = rating;
    this.speciality = speciality;
    this.name = name;
    this.currencie_id = currencie_id;
    this.countrie_id = countrie_id;
    this.group_id = group_id;
    this.countrie_id = countrie_id;
    this.data = data;
    this.adresse = adresse;
  }
}

export class HealthcareProviders {
  id?: number;
  currencie_id?: number;
  group_id?: number;
  rating?: number;
  data?: any;
  name?: string;
  adresse?: any;
  type?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: any;
  custom_margin_kimbo?: number | null;
  custom_margin_corporate?: number | null;
  title?: Title;
  description?: Description;
  image_profil?: ImageProfil;
  speciality?: string[];
  images?: ImageProfil[];
  country?: ICountryModel;
  country_id?: number;
  countrie_id?: number;
  payment_method?: any;
  legal_documents?: IFile[];

  constructor(
    id?: number,
    currencie_id?: number,
    group_id?: number,
    rating?: number,
    data?: any,
    name?: string,
    adresse?: any,
    type?: string,
    created_at?: string,
    updated_at?: string,
    deleted_at?: any,
    title?: Title,
    description?: Description,
    image_profil?: ImageProfil,
    speciality?: string[],
    custom_margin_kimbo?: number | null,
    custom_margin_corporate?: number | null,
    country?: ICountryModel,
    country_id?: number,
    countrie_id?: number,
    payment_method?: any
  ) {
    this.id = id;
    this.currencie_id = currencie_id;
    this.group_id = group_id;
    this.rating = rating;
    this.data = data;
    this.name = name;
    this.adresse = adresse;
    this.country = country;
    this.country_id = country_id;
    this.countrie_id = countrie_id;
    this.type = type;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
    this.title = title;
    this.description = description;
    this.speciality = speciality;
    this.custom_margin_kimbo = custom_margin_kimbo;
    this.custom_margin_corporate = custom_margin_corporate;
    this.payment_method = payment_method;
  }
}

export class HcpOnboardingStore {
  specialities: string;
  name: string;
  type: string;
  currency: number;
  country: string;
  city: string;
  modules: string;
  members: string;
  description_fr: string;
  description_en: string;
  lang: string;
  longitude: string;
  latitude: string;
  street: string;
  postcode: string | number;
  state: string;
  website: string;
  social: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
  files: File[];
  tel: string;
  email: string;
  legal_documents: File[];
  payment_method: any;

  constructor(
    specialities: string,
    name: string,
    type: string,
    currency: number,
    country: string,
    city: string,
    modules: string,
    members: string,
    description_fr: string,
    description_en: string,
    lang: string,
    longitude: string,
    latitude: string,
    street: string,
    postcode: string | number,
    state: string,
    website: string,
    social: string,
    monday: string,
    tuesday: string,
    wednesday: string,
    thursday: string,
    friday: string,
    saturday: string,
    sunday: string,

    files: File[],
    tel: string,
    email: string,
    legal_documents: File[],
    payment_method: any
  ) {
    this.specialities = specialities;
    this.name = name;
    this.type = type;
    this.currency = currency;
    this.country = country;
    this.city = city;

    this.modules = modules;
    this.members = members;
    this.description_fr = description_fr;
    this.description_en = description_en;
    this.lang = lang;
    this.longitude = longitude;
    this.latitude = latitude;
    this.street = street;
    this.postcode = postcode;
    this.state = state;
    this.website = website;
    this.social = social;
    this.monday = monday;
    this.tuesday = tuesday;
    this.wednesday = wednesday;
    this.thursday = thursday;
    this.friday = friday;
    this.saturday = saturday;
    this.sunday = sunday;

    this.files = files;
    this.tel = tel;
    this.email = email;
    this.legal_documents = legal_documents;
    this.payment_method = payment_method;
    this.longitude = longitude;
    this.latitude = latitude;
  }
}

export class HcpOnboardingVerif {
  email: string;
  code: number;
  constructor(email: string, code: number) {
    this.email = email;
    this.code = code;
  }
}

export interface CountryHealthcareProvider {
  country: string;
  healthcareproviders: IHealthcareProvider[];
}
