<h1 class="text-2xl font-bold text-navy mb-4" mat-dialog-title>
  {{ healthcareProvider.title.languages | textLanguages }}
</h1>
<button
  class="close-button"
  color="primary"
  mat-dialog-close
  mat-button
  aria-label="Example icon button with a delete icon">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  <div
    class="w-full lg:flex {{
      !healthcareProvider.can_purchase ? 'mt-4 lg:mt-6' : 'mt-6 lg:mt-8'
    }}">
    <div class="block w-full">
      <div
        *ngIf="!healthcareProvider.can_purchase"
        class="p-4 mb-4 text-sm md:text-base text-kimbo-blue rounded-md bg-kimbo-blue bg-opacity-5 flex justify-center gap-2 items-center"
        role="alert">
        <i class="ri-information-2-line text-xl"></i>
        <span>
          {{ 'kimbo-layout.cannot_purchase_msg' | translate }}
        </span>
      </div>
      <app-images-viewer
        class="w-full block {{
          healthcareProvider.images!.length === 1 ? 'mb-6' : ''
        }}"
        [images]="healthcareProvider.images!"
        [type]="healthcareProvider.type"
        [currentImage]="healthcareProvider!.images![0]"></app-images-viewer>

      <div class="mx-2 mb-4">
        <div
          class="flex flex-col md:flex-row items-start gap-3 justify-between mt-8">
          <app-detail
            *ngIf="
              healthcareProvider.adresse.name ||
              (healthcareProvider.adresse.city &&
                healthcareProvider.adresse.country)
            "
            class="w-full sm:w-1/2"
            [label]="'kimbo-healthcare-provider.location-label' | translate">
            {{
              healthcareProvider.adresse.name
                ? healthcareProvider.adresse.name
                : healthcareProvider.adresse.city +
                  '-' +
                  healthcareProvider.adresse.country
            }}
          </app-detail>
          <app-detail
            *ngIf="healthcareProvider.type"
            class="w-full mt-4 sm:mt-0 sm:w-1/2 xl:w-1/4"
            [label]="'kimbo-healthcare-provider.hcp-type-label' | translate">
            {{
              healthcareProvider.type
                ? ('common.' + healthcareProvider.type | translate)
                : '–'
            }}
          </app-detail>
          <app-detail
            *ngIf="healthcareProvider.rating > 0"
            class="w-full mt-4 sm:w-1/2 xl:mt-0 xl:w-1/4"
            [label]="'kimbo-healthcare-provider.rating-label' | translate">
            <app-rating [rate]="healthcareProvider.rating"></app-rating>
          </app-detail>

          <!--app-detail
            *ngIf="
              healthcareProvider &&
              (healthcareProvider.data.contact ||
                healthcareProvider.adresse.contact)
            "
            class="w-full mt-4 sm:w-1/2"
            [label]="'kimbo-healthcare-provider.contact-label' | translate">
            {{
              healthcareProvider.data.contact
                ? healthcareProvider.data.contact
                : healthcareProvider.adresse.contact
                ? healthcareProvider.adresse.contact
                : ''
            }}
          </!--app-detail-->
          <app-detail
            *ngIf="patientReviews && patientReviews > 0"
            class="w-full mt-4 sm:w-1/2"
            [label]="
              'kimbo-healthcare-provider.patient-reviews-label' | translate
            ">
            <app-rating
              [rate]="this.patientReviews"
              class="block mt-3"></app-rating>
          </app-detail>
        </div>

        <div *ngIf="healthcareProvider.description" class="mt-6">
          <h4
            class="text-base font-bold text-navy border-l-4 border-kimbo-blue pl-2 rounded-l-sm">
            {{ 'kimbo-healthcare-provider.description-label' | translate }}
          </h4>
          <p class="py-4 px-2">
            {{ healthcareProvider.description.languages | textLanguages }}
          </p>
          <span
            *ngIf="!healthcareProvider.description"
            class="italic text-dark-gray"
            >{{ 'kimbo-layout.missing-description' | translate }}</span
          >
        </div>

        <div
          *ngIf="(healthcareProvider?.speciality?.length ?? 0) > 0"
          class="mt-6">
          <h4
            class="text-base font-bold text-navy border-l-4 border-kimbo-blue pl-2 rounded-l-sm">
            {{ 'kimbo-healthcare-provider.specialities-label' | translate }}
          </h4>
          <div class="flex flex-wrap items-start justify-start gap-3 py-4 px-2">
            <div
              *ngFor="let item of healthcareProvider.speciality ?? []"
              class="mb-1">
              <span
                *ngIf="item"
                class="px-4 py-1 text-sm font-medium text-white rounded-md bg-kimbo-blue"
                >{{ 'common.' + item | translate }}
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="hasOpeningHours()" class="w-full mt-6">
          <h4
            class="text-base font-bold text-navy border-l-4 border-kimbo-blue pl-2 rounded-l-sm">
            {{ 'kimbo-healthcare-provider.opening-hours-label' | translate }}
          </h4>
          <div class="flex flex-wrap items-start justify-start gap-5 pb-4 px-2">
            <div class="mt-4">
              <p>
                {{ 'admin-fields.monday-label' | translate }}
              </p>
              <span
                class="px-4 text-sm font-medium text-white rounded-md bg-kimbo-blue py-1">
                {{
                  healthcareProvider.data.hours_overture?.monday === 'closed'
                    ? ('common.closed' | translate)
                    : healthcareProvider.data.hours_overture?.monday ?? '-'
                }}
              </span>
            </div>

            <div class="mt-4">
              <p>
                {{ 'admin-fields.tuesday-label' | translate }}
              </p>
              <span
                class="px-4 text-sm font-medium text-white rounded-md bg-kimbo-blue py-1">
                {{
                  healthcareProvider.data.hours_overture?.tuesday === 'closed'
                    ? ('common.closed' | translate)
                    : healthcareProvider.data.hours_overture?.tuesday ?? '-'
                }}
              </span>
            </div>

            <div class="mt-4">
              <p>
                {{ 'admin-fields.wednesday-label' | translate }}
              </p>
              <span
                class="px-4 text-sm font-medium text-white rounded-md bg-kimbo-blue py-1">
                {{
                  healthcareProvider.data.hours_overture?.wednesday === 'closed'
                    ? ('common.closed' | translate)
                    : healthcareProvider.data.hours_overture?.wednesday ?? '-'
                }}
              </span>
            </div>
            <div class="mt-4">
              <p>
                {{ 'admin-fields.thursday-label' | translate }}
              </p>
              <span
                class="px-4 text-sm font-medium text-white rounded-md bg-kimbo-blue py-1">
                {{
                  healthcareProvider.data.hours_overture?.thursday === 'closed'
                    ? ('common.closed' | translate)
                    : healthcareProvider.data.hours_overture?.thursday ?? '-'
                }}
              </span>
            </div>
            <div class="mt-4">
              <p>
                {{ 'admin-fields.friday-label' | translate }}
              </p>
              <span
                class="px-4 text-sm font-medium text-white rounded-md bg-kimbo-blue py-1">
                {{
                  healthcareProvider.data.hours_overture?.friday === 'closed'
                    ? ('common.closed' | translate)
                    : healthcareProvider.data.hours_overture?.friday ?? '-'
                }}
              </span>
            </div>
            <div class="mt-4">
              <p>
                {{ 'admin-fields.saturday-label' | translate }}
              </p>
              <span
                class="px-4 text-sm font-medium text-white rounded-md bg-kimbo-blue py-1">
                {{
                  healthcareProvider.data.hours_overture?.saturday === 'closed'
                    ? ('common.closed' | translate)
                    : healthcareProvider.data.hours_overture?.saturday ?? '-'
                }}
              </span>
            </div>
            <div class="mt-4">
              <p>
                {{ 'admin-fields.sunday-label' | translate }}
              </p>
              <span
                class="px-4 text-sm font-medium text-white rounded-md bg-kimbo-blue py-1">
                {{
                  healthcareProvider.data.hours_overture?.sunday === 'closed'
                    ? ('common.closed' | translate)
                    : healthcareProvider.data.hours_overture?.sunday ?? '-'
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
