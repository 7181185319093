import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { isEmptyString } from 'src/app/core/utils/core';
import { OptionObject } from 'src/app/core/utils/type';
import { CountrySelectionComponent } from './components/country-selection/country-selection.component';
import { ServicesService } from 'src/app/core/services/services.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.scss'],
})
export class CountryDropdownComponent implements OnInit {
  isOpen: boolean = false;
  @Input() options: OptionObject[] = [];
  @Input() classNames: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  @Input() pickValue?: OptionObject;
  @Input() withLabel = true;
  @Input() forCampaign = false;

  constructor(
    private services: ServicesService,
    private changeDet: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.pickValue && !isEmptyString(this.pickValue.label)) {
      let countryCode = this.pickValue.label as string;
      this.pickValue.value = this.translateService.instant(
        'common.' + countryCode.toLowerCase()
      );
      this.triggerEvent.emit(this.pickValue);
    } else {
      if (!this.forCampaign && this.options?.length > 0)
        this.pickValue = this.options[0];
      this.triggerEvent.emit(this.pickValue);
    }
  }

  countryTranslation(options: OptionObject[]): OptionObject[] {
    let countries: OptionObject[] = [];
    options.forEach(country => {
      countries.push({
        label: country.label,
        value: this.translateService.instant(
          'common.' + country.label.toLowerCase()
        ),
        id: country.id,
      });
    });
    return countries;
  }

  openfilterSelect(): void {
    this.options = this.countryTranslation(this.options);
    this.services.modalService
      .openModal(CountrySelectionComponent, {
        width: '400px',
        height: '500px',
        data: {
          countryOptions: this.countryTranslation(this.options),
        },
      })
      .subscribe((data: OptionObject) => {
        if (data) {
          if (!this.forCampaign) this.pickValue = data;
          this.triggerEvent.emit(data);
        }
      });
  }
}
