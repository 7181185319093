import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { PostLoginAtions } from 'src/app/core/enums/enums';
import { ServicesService } from 'src/app/core/services/services.service';

import { LoginPostActionParams } from 'src/app/core/utils/type';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit, OnDestroy {
  $closeDialoSub!: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      action?: PostLoginAtions;
      params?: LoginPostActionParams;
      invitation?: {
        key: string | null;
        mode: string | null;
        identifier: string | null;
        whiteLabelId: string | null;
      };
      caller: '_SIGN_UP' | '_SIGN_IN';
    },
    public dialogRef: MatDialogRef<LoginModalComponent>,
    private services: ServicesService
  ) {}

  ngOnDestroy(): void {
    this.$closeDialoSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.services.eventService.subscribe('close:dialog', () =>
      this.dialogRef.close()
    );
  }
}
