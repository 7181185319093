import { BreakpointState } from '@angular/cdk/layout';
import { Component, Renderer2 } from '@angular/core';
import { BreakpointMatcherService } from 'src/app/core/services/breakpoint-matcher.service';
import { ServicesService } from 'src/app/core/services/services.service';

@Component({
  selector: 'app-benefit-layout',
  templateUrl: './benefit-layout.component.html',
  styleUrls: ['./benefit-layout.component.scss'],
})
export class BenefitLayoutComponent {
  isSideBarOpen = false;
  breackpointMatche = false;
  isWhiteLabel!: boolean;
  constructor(
    public breakpointMatcherService: BreakpointMatcherService,
    private services: ServicesService,
    private renderer: Renderer2
  ) {
    this.isWhiteLabel = !!this.services.sessionService.getWhiteLabel();
    this.setHtmlClass(this.isWhiteLabel ? 'familycare' : 'kimbo');
    this.breakpointMatcherService
      .initObserver('2xl')
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.breackpointMatche = true;
          this.isSideBarOpen = true;
        } else {
          this.breackpointMatche = false;
          this.isSideBarOpen = false;
        }
      });
  }

  setIsSideBarOpen(isOpen: boolean): void {
    this.isSideBarOpen = isOpen;
  }

  setHtmlClass(className: string) {
    const htmlElement = this.renderer.selectRootElement('html', true);

    // Remove any previously added custom classes
    this.renderer.removeClass(htmlElement, 'familycare');
    this.renderer.removeClass(htmlElement, 'kimbo');

    // Add the new class
    this.renderer.addClass(htmlElement, className);
  }
}
