export const INPUT_CLASSES = {
  common:
    'w-full rounded-lg px-4 bg-[#f4f8fc] placeholder-dark-gray focus:outline-none focus:shadow-inner-blue h-14',
  common1:
    'w-full rounded-lg px-4 bg-[#f4f8fc] placeholder-dark-gray focus:outline-none h-14',
  texarea:
    'w-full rounded-lg px-4 bg-[#f4f8fc] placeholder-dark-gray focus:outline-none focus:shadow-inner-blue',
  basic: 'w-full rounded-lg px-4 bg-light-gray placeholder-dark-gray h-14',
  invalid: 'focus:border-red border-1',
  valid: 'focus:shadow-inner-blue shadow-inner-blue',
  disabled: 'text-dark-gray cursor-default',
};

export const SCREEN_SIZES = {
  mobile: 30, // Limite pour couper le texte sur mobile
  tablet: 50, // Limite pour couper le texte sur tablette
  pc: 70, // Limite pour couper le texte sur PC
};

export const BACK_BUTTON =
  'h-12 w-12 cursor-pointer inline-flex items-center justify-center rounded-full border-1 border-blue hover:opacity-75 focus:outline-none focus:shadow-blue';
export const USER_KEY = 'USER_KEY';
export const TWO_FA_KEY = 'TWO_FA_KEY';
export const CONFIG_TWO_FA_KEY = 'CONFIG_TWO_FA_KEY';
export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';
export const CURRENT_ROLE = 'CURRENT_ROLE';
export const CURRENCIE = 'CURRENCIE';
export const CURRENCIE_USER = 'CURRENCIE_USER';
export const CURRENCIE_HCP = 'CURRENCIE_HCP';
export const INTOUCH_PAYMENT_INFO = 'INTOUCH_PAYMENT_INFO';
export const DEFAULT_COUNTRY = 'DEFAULT_COUNTRY';
export const DEFAULT_COUNTRY_CITY = 'DEFAULT_COUNTRY_CITY';
export const LANG = 'LANG';
export const PUSHNOTIFICATION = 'PUSHNOTIFICATION';
export const NO_SHOW_DAY = 15;
export const CURRENCIES = 'CURRENCIES';
export const DOWNLOAD_SNACK = 'DOWNLOAD_SNACK';
export const CLOSE_BONUS_ALERT = 'CLOSE_BONUS_ALERT';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const SHOW_PRE_AUTH_MODAL = 'SHOW_PRE_AUTH_MODAL';
export const TIME_SPEND_AS_GUEST = 'TIME_SPEND_AS_GUEST';
export const MARK_CONSENT = 'MARK_CONSENT';
export const _TIME_SPEND_AS_GUEST = 30000; // in mili seconds
export const IBANMASK = 'SS00 0000 0000 0000 0000 0000 0000 00';
export const WHITE_LABEL = 'WHITE_LABEL';
export const MAP = {
  MAP_CENTER: {
    LAT: 7.365302,
    LNG: 12.343439499999988,
  },
  MAP_CENTER_AFRICA: {
    LAT: -8.783195,
    LNG: 34.50852299999997,
  },
};
export const DEFAULT_IMAGE = 'assets/icons/ms-icon-310x310.png';
export const DEFAULT_IMAGE_KIMBO = 'assets/images/groupk.png';
export const DEFAULT_IMAGE_BENEFIT = 'assets/images/Insurance.png';
export const DEFAULT_IMAGE_HCP = 'assets/images/hospital-building.png';
export const DEFAULT_IMAGE_CORPORATE = 'assets/images/office-buildingk.png';
export const PAYMENT_METHOD = {
  CASH: 'cash',
  STRIPE: 'stripe',
  MOBILE: 'mobile',
  BANK_TRANSFERT: 'bank_transfer',
  WALLET: 'wallet',
};
export const TUTORIAL = {
  TUTORIAL_KIMBO_HOME: 'TUTORIAL_KIMBO_HOME',
  TUTORIAL_KIMBO_BUNDLE: 'TUTORIAL_KIMBO_BUNDLE',
  TUTORIAL_KIMBO_HEALTHPROVIDER: 'TUTORIAL_KIMBO_HEALTHPROVIDER',
  TUTORIAL_HCP_CHECKIN: 'TUTORIAL_HCP_CHECIN',
  TUTORIAL_HCP_CHECKOUT: 'TUTORIAL_KIMBO_HOME',
  TUTORIAL_HCP_ASSIGN: 'TUTORIAL_HCP_ASSIGN',
  TUTORIAL_GLOBAL: 'TUTORIAL_GLOBAL',
};

export const MAX_FILE_SIZE = 4000000; // in bytes

export const SPECIALITIES = [
  { key: 'chirurgie', active: false },
  { key: 'dermatologie', active: false },
  { key: 'endocrinologie', active: false },
  { key: 'geriatrie', active: false },
  { key: 'gynecologie', active: false },
  { key: 'hematologie', active: false },
  { key: 'radiologie', active: false },
  { key: 'pharmacy', active: false },
  { key: 'radiotherapie', active: false },
  { key: 'rhumatologie', active: false },
  { key: 'psychiatrie', active: false },
  { key: 'pneumologie', active: false },
  { key: 'pediatrie', active: false },
  { key: 'oto-rhino-laryngologie', active: false },
  { key: 'orthopedie', active: false },
  { key: 'ophtalmologie', active: false },
  { key: 'obstetrique', active: false },
  { key: 'oncologie', active: false },
  { key: 'odontologie', active: false },
  { key: 'neurologie', active: false },
  { key: 'gastro-enterologie', active: false },
  { key: 'hepatologie', active: false },
  { key: 'infectiologie', active: false },
  { key: 'neonatologie', active: false },
  { key: 'nephrologie', active: false },
  { key: 'medecine-general', active: false },
  { key: 'maternite', active: false },
  { key: 'urologie', active: false },
  { key: 'cardiologie', active: false },
  { key: 'neurochirurgie', active: false },
  { key: 'anesthesiology', active: false },
  { key: 'andrology', active: false },
  { key: 'cardiac-surgery', active: false },
  { key: 'general-surgery', active: false },
  { key: 'maxillo-facial', active: false },
  { key: 'pediatric', active: false },
  { key: 'thoracic-surgery', active: false },
  { key: 'vascular-surgery', active: false },
  { key: 'geriatrics', active: false },
  { key: 'acute-medicine', active: false },
  { key: 'occupational-medicine', active: false },
  { key: 'internal-medicine', active: false },
  { key: 'nuclear-medicine', active: false },
  { key: 'palliative-medicine', active: false },
  { key: 'physical-medicine', active: false },
  { key: 'preventive-medicine', active: false },
  { key: 'aesthetic', active: false },
  { key: 'health-promotion', active: false },
  { key: 'alternative-medicine', active: false },
  { key: 'complement-medicine', active: false },
];

export const CHART = {
  PIE: 'pie',
  LINE: 'line',
};
export const DATEFRENCHOPTION: any = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
};
export const SHOW_EVALUATE_MODAL = 'SHOW_EVALUATE_MODAL';
export const EVALUATE_MODAL_LAUNCHED = 'EVALUATE_MODAL_LAUNCHED';
export const APP_RATED = 'APP_RATED';
export const SEARCH_VALUE = 'SEARCH_VALUE';
