import { HttpClient } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/admin-settings/', suffix: '.json' },
    { prefix: './assets/i18n/admin-create-discount-card/', suffix: '.json' },
    {
      prefix: './assets/i18n/admin-create-healthcare-module/',
      suffix: '.json',
    },
    {
      prefix: './assets/i18n/admin-create-healthcare-provider/',
      suffix: '.json',
    },
    { prefix: './assets/i18n/admin-create-medical-service/', suffix: '.json' },
    { prefix: './assets/i18n/admin-create-referral-code/', suffix: '.json' },
    { prefix: './assets/i18n/admin-discount-cards/', suffix: '.json' },
    { prefix: './assets/i18n/admin-edit-discount-card/', suffix: '.json' },
    {
      prefix: './assets/i18n/admin-edit-financial-transaction/',
      suffix: '.json',
    },
    { prefix: './assets/i18n/admin-edit-healthcare-module/', suffix: '.json' },
    {
      prefix: './assets/i18n/admin-edit-healthcare-provider/',
      suffix: '.json',
    },
    {
      prefix: './assets/i18n/admin-beneficiaries-management/',
      suffix: '.json',
    },
    { prefix: './assets/i18n/admin-edit-user/', suffix: '.json' },
    { prefix: './assets/i18n/admin-exchange-rates/', suffix: '.json' },
    { prefix: './assets/i18n/admin-fields/', suffix: '.json' },
    { prefix: './assets/i18n/admin-financial-transactions/', suffix: '.json' },
    { prefix: './assets/i18n/admin-healthcare-modules/', suffix: '.json' },
    { prefix: './assets/i18n/admin-healthcare-providers/', suffix: '.json' },
    { prefix: './assets/i18n/admin-layout/', suffix: '.json' },
    { prefix: './assets/i18n/admin-medical-services/', suffix: '.json' },
    { prefix: './assets/i18n/admin-settings/', suffix: '.json' },
    { prefix: './assets/i18n/admin-country/', suffix: '.json' },
    { prefix: './assets/i18n/admin-users/', suffix: '.json' },
    { prefix: './assets/i18n/admin-role/', suffix: '.json' },
    { prefix: './assets/i18n/admin-white-label/', suffix: '.json' },
    { prefix: './assets/i18n/common/', suffix: '.json' },
    { prefix: './assets/i18n/corporate-dashboard/', suffix: '.json' },
    { prefix: './assets/i18n/corporate-campaign/', suffix: '.json' },
    { prefix: './assets/i18n/errors/', suffix: '.json' },
    { prefix: './assets/i18n/forms/', suffix: '.json' },
    { prefix: './assets/i18n/hcp-common/', suffix: '.json' },
    { prefix: './assets/i18n/hcp-home/', suffix: '.json' },
    { prefix: './assets/i18n/hcp-layout/', suffix: '.json' },
    { prefix: './assets/i18n/hcp-registration/', suffix: '.json' },
    { prefix: './assets/i18n/kimbo-create-contact/', suffix: '.json' },
    { prefix: './assets/i18n/kimbo-healthcare-provider/', suffix: '.json' },
    { prefix: './assets/i18n/kimbo-home/', suffix: '.json' },
    { prefix: './assets/i18n/kimbo-layout/', suffix: '.json' },
    { prefix: './assets/i18n/kimbo-module/', suffix: '.json' },
    { prefix: './assets/i18n/kimbo-modules/', suffix: '.json' },
    { prefix: './assets/i18n/kimbo-profile/', suffix: '.json' },
    { prefix: './assets/i18n/kimbo-wallet/', suffix: '.json' },
    { prefix: './assets/i18n/kimbo-send-credits/', suffix: '.json' },
    { prefix: './assets/i18n/kimbo-top-up-mobile-money/', suffix: '.json' },
    { prefix: './assets/i18n/kimbo-top-up/', suffix: '.json' },
    { prefix: './assets/i18n/sign-in/', suffix: '.json' },
    { prefix: './assets/i18n/sign-up-done/', suffix: '.json' },
    { prefix: './assets/i18n/sign-up/', suffix: '.json' },
    { prefix: './assets/i18n/tutorial/', suffix: '.json' },
    { prefix: './assets/i18n/benefit-layout/', suffix: '.json' },
    { prefix: './assets/i18n/subscription/', suffix: '.json' },
  ]);
}
