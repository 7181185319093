import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country, Currency, CurrencyStore } from '../../models/Common';
import { SessionService } from '../../services/session.service';

// Liste des pays de l'Union Européenne (EUR)
const europeanUnionCountries: string[] = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

// Mapping pays → devise
const countryToCurrency: Record<string, string> = {
  US: 'USD',
  CA: 'USD',
  CH: 'CHF',
  KE: 'KES',
  CM: 'XAF',
  BJ: 'XAF',
  SN: 'XAF',
  CI: 'XAF',
  GA: 'XAF',
  TD: 'XAF',
};

// Ajouter tous les pays de l'UE avec EUR comme devise
europeanUnionCountries.forEach(country => {
  countryToCurrency[country] = 'EUR';
});

// Devise par défaut

@Injectable()
export class CurrencyService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Index
   * List all Currencies
   */
  public index(): Observable<Currency[]> {
    return this.http.get<Currency[]>(
      `${this.basePath}/v1/resources/currencie`,
      this.httpOptions
    );
  }

  /**
   * View
   * @param currencieId the currencie identifier
   */
  public view(currencieId: number): Observable<Currency> {
    return this.http.get<Currency>(
      `${this.basePath}/v1/resources/currencie/${currencieId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   */
  public store(body?: CurrencyStore): Observable<Currency> {
    return this.http.post<Currency>(
      `${this.basePath}/v1/resources/currencie`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param curencieId
   */
  public update(
    curencieId: number,
    body?: CurrencyStore
  ): Observable<Currency> {
    return this.http.put<Currency>(
      `${this.basePath}/v1/resources/currencie/${curencieId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Delete
   * @param curencieId
   */
  public delete(curencieId: number): Observable<Currency> {
    return this.http.delete<Currency>(
      `${this.basePath}/v1/resources/currencie/${curencieId}`,
      this.httpOptions
    );
  }

  getCurrencyByIP(
    currentIpCountry: Country,
    currencies: Currency[]
  ): Observable<Currency> {
    const defaultCurrency = currencies.find(c => c.code === 'CHF')!;
    return of(currentIpCountry).pipe(
      map(response => {
        const country = response.country; // Code pays (ex: FR, US, CH)
        const currencyCode = countryToCurrency[country] || 'CHF';
        return currencies.find(c => c.code === currencyCode) || defaultCurrency;
      }),
      catchError(error => {
        console.error(
          'Erreur lors de la récupération de la localisation:',
          error
        );
        return of(defaultCurrency);
      })
    );
  }
}
