<div class="flex flex-col md:flex-row justify-between">
  <div class="w-full md:w-1/2 flex flex-wrap justify-between md:pr-3">
    <div
      *ngIf="buyedPackage.patient"
      class="w-full flex items-center justify-start">
      <div class="flex-grow">
        <div class="flex items-center mb-4">
          <div class="relative w-fit h-fit">
            <app-avatar
              [classNames]="'w-24 h-24'"
              class="{{
                buyedPackage!.patient!.image_profil!.path.endsWith('unknow.png')
                  ? ''
                  : 'cursor-pointer'
              }}"
              [withFrame]="true"
              [src]="
                buyedPackage!.patient!.image_profil!.path.endsWith(
                  'unknow.png'
                ) && buyedPackage!.patient!.image
                  ? (buyedPackage!.patient!.image | imageToUrl)
                  : (buyedPackage!.patient!.image_profil | imageToUrl)
              "></app-avatar>
          </div>

          <div class="ml-4">
            <h2 class="text-lg font-bold text-navy">
              {{
                buyedPackage.patient.adresse &&
                  buyedPackage.patient.adresse!.first_name
              }}
              {{
                buyedPackage.patient.adresse &&
                  buyedPackage.patient.adresse!.last_name
              }}
            </h2>
            <h3 class="text-sm text-navy">
              {{ _formatPhoneNumber(buyedPackage.patient.tel) }}
            </h3>
          </div>
        </div>
        <div class="my-4">
          <ng-container [ngTemplateOutlet]="patientWallet"></ng-container>
        </div>
      </div>
    </div>
    <div>
      <!--button
      *ngIf="caller === '_INTREATMENT'"
      class="absolute float-right mb-2"
      mat-raised-button
      color="warn"
      type="button"
      [disabled]="buyedPackage.id === currentLogId"
      (click)="openPatientCheckoutModalDialog()">
      <span *ngIf="buyedPackage.id !== currentLogId">{{
        'hcp-common.check-out' | translate
      }}</span>
      <app-spinner
        *ngIf="buyedPackage.id === currentLogId"
        class="inline-block w-5 h-5 filter-blue"></app-spinner>
    </button-->
    </div>
  </div>
  <div
    class="w-full md:w-1/2 md:border-l-1 md:border-light-gray flex flex-wrap gap-4 items-stretch justify-between mt-4 mb-4 pl-1 pr-1 md:pl-3 md:pr-0">
    <div
      *ngFor="
        let healthcareModule of _buyedPackage!.healthcarepackages;
        let i = index
      "
      class="relative flex flex-col items-center justify-center w-full sm:w-1/2-with-gap md:w-full h-fit px-5 py-3 mt-3 transition-all bg-white rounded-xl handle-click shadow-backdrop-light">
      <div class="absolute w-fit h-fit -top-5 handle-click">
        <img
          appImg
          class="w-10 h-10 mb-3 fill-current handle-click"
          [src]="healthcareModule.image_profil | imageToUrl"
          alt="module image" />
      </div>

      <div class="flex items-center justify-between w-full gap-2 handle-click">
        <div class="text-base font-semibold text-center text-navy handle-click">
          {{
            healthcareModule.title.languages | textLanguages : currentLanguage
          }}
        </div>
        <app-info-component
          *ngIf="healthcareModule.description"
          [info]="
            healthcareModule.description.languages | textLanguages
          "></app-info-component>
      </div>
    </div>

    <!-- Add a ghost element so `justify-content: space-between` works correctly
     when we have 2 elements in the last row, when the rest of rows have 3 of them.-->
    <div
      *ngIf="
        _buyedPackage!.healthcarepackages &&
        _buyedPackage!.healthcarepackages!.length % 2 === 1
      "
      class="hidden lg:block lg:w-1/2-with-gap"></div>
  </div>
</div>

<ng-template #patientWallet>
  <div
    class="w-full sm:w-fit font-bold transition-all bg-opacity-10 text-kimbo-blue bg-kimbo-blue rounded-xl p-3 mx-auto md:m-0">
    <div class="flex justify-start items-center gap-2">
      <i class="ri-wallet-3-fill text-xl"></i>

      <div
        *ngIf="!caller || caller !== '_FINISHED'"
        class="text-[14px] font-bold">
        {{ 'hcp-common.rest-amount' | translate }}
        {{
          _toMoney(buyedPackage.health_credit_amount) -
            _toMoney(_buyedPackage?.file_amount ?? 0) +
            ' ' +
            currency.symbol
        }}
      </div>

      <div *ngIf="caller === '_FINISHED'" class="text-sm font-bold">
        {{
          'hcp-common.final-invoice-amount'
            | translate
              : {
                  amount: _toMoney(_buyedPackage?.file_amount ?? 0),
                  currency: currency.symbol
                }
        }}
      </div>
    </div>
  </div>
</ng-template>
