import { inject } from '@angular/core';
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { SessionService } from '../services/session.service';
import { CustomNavigationService } from '../services/custom-navigation.service';
import { Observable } from 'rxjs';

export function HasNotSubsGuard(): CanActivateFn {
  return (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const authService: SessionService = inject(SessionService);
    const navigationService: CustomNavigationService = inject(
      CustomNavigationService
    );
    return !authService.getUser().has_subscription!;
  };
}
