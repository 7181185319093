import { Component, OnInit, Renderer2 } from '@angular/core';
import { ServicesService } from 'src/app/core/services/services.service';

@Component({
  selector: 'app-pre-auth-layout',
  templateUrl: './pre-auth-layout.component.html',
  styleUrls: ['./pre-auth-layout.component.scss'],
})
export class PreAuthLayoutComponent {
  isWhiteLabel!: boolean;
  constructor(private services: ServicesService, private renderer: Renderer2) {
    this.isWhiteLabel = !!this.services.sessionService.getWhiteLabel();
    this.setHtmlClass(this.isWhiteLabel ? 'familycare' : 'kimbo');
  }
  setHtmlClass(className: string) {
    const htmlElement = this.renderer.selectRootElement('html', true);

    // Remove any previously added custom classes
    this.renderer.removeClass(htmlElement, 'familycare');
    this.renderer.removeClass(htmlElement, 'kimbo');

    // Add the new class
    this.renderer.addClass(htmlElement, className);
  }
}
