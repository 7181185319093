import { Injectable } from '@angular/core';
import { PermissionEnums } from '../enums/permission-enums';
import { SessionService } from './session.service';
import { IUser } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class AdminRoleService {
  constructor(private sessionService: SessionService) {}

  public hasPermissions(permissions: PermissionEnums[], country_id?: number) {
    return permissions.some(perm => this.hasPermission(perm, country_id));
  }

  public hasPermission(
    permission: PermissionEnums,
    country_id?: number
  ): boolean {
    const user: IUser = this.sessionService.getUser();
    if (!user?.adminroles || user.adminroles.length === 0) {
      return false;
    }

    return user.adminroles.some(role =>
      role.adminpermissions.some(perm => {
        if (perm.rights === PermissionEnums.SuperAdmin) {
          return true;
        }

        if (country_id) {
          return (
            perm.rights === permission &&
            (perm.pivot?.scopable_id === null ||
              perm.pivot.scopable_id === country_id)
          );
        }
        return perm.rights === permission;
      })
    );
  }
}
