<div *ngIf="data.bankInfos">
  <div class="text-lg mt-2 mb-2 w-full font-semibold justify-start">
    {{ 'kimbo-send-credits.bank-coodinate' | translate }}
  </div>

  <div class="my-8 text-navy" [innerHTML]="data.bankInfos"></div>

  <div class="my-2 justify-center">
    <button
      class="w-full h-12"
      mat-raised-button
      color="primary"
      type="button"
      (click)="close()">
      {{ 'kimbo-send-credits.close' | translate }}
    </button>
  </div>
</div>

<div *ngIf="data.isMobile" class="flex items-center flex-col text-center">
  <img
    appImg
    src="assets/gif/e-wallet-animate.svg"
    class="w-64"
    alt="Wallet stripe" />
  <div
    *ngIf="!walletIsEmpty() && data.callerTo === 'BUY'"
    class="my-4 text-navy text-center">
    {{
      'common.wallet-debit-msg'
        | translate
          : {
              amount: _toMoney(
                toNumber(currentRole.group.health_credit_amount),
                userCurrency.rate
              ),
              currency: userCurrency.symbol,
              amount_hc:
                toNumber(currentRole.group.health_credit_amount) | roundPipe
            }
    }}
  </div>
  <div
    class="{{
      !walletIsEmpty() && data.callerTo === 'BUY' ? 'mb-4' : 'my-4'
    }} text-navy text-center">
    {{
      'common.mobile-debit-message'
        | translate
          : {
              amount:
                data.callerTo === 'BUY'
                  ? _toMoney(
                      data.totalPrice -
                        toNumber(currentRole.group.health_credit_amount),
                      userCurrency.rate
                    )
                  : data.totalPrice,
              currency: userCurrency.symbol,
              amount_hc:
                (data.callerTo === 'BUY'
                  ? data.totalPrice -
                    toNumber(currentRole.group.health_credit_amount)
                  : _toHealthCredit(data.totalPrice)
                ) | roundPipe
            }
    }}
  </div>
  <div class="w-10/12 flex items-center justify-center h-20 mx-auto">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>

<div *ngIf="data.stripeInfos" class="flex items-center flex-col text-center">
  <img
    appImg
    src="assets/gif/e-wallet-animate.svg"
    class="w-64"
    alt="Wallet stripe" />
  <div
    *ngIf="!walletIsEmpty() && data.callerTo === 'BUY'"
    class="my-4 text-navy text-center">
    {{
      'common.wallet-debit-msg'
        | translate
          : {
              amount: _toMoney(
                toNumber(currentRole.group.health_credit_amount),
                userCurrency.rate
              ),
              currency: userCurrency.symbol,
              amount_hc:
                toNumber(currentRole.group.health_credit_amount) | roundPipe
            }
    }}
  </div>
  <div class="{{ !walletIsEmpty() ? 'mb-4' : 'my-4' }} text-navy text-center">
    {{
      'common.credit-card-debit-msg'
        | translate
          : {
              amount:
                data.callerTo === 'BUY'
                  ? _toMoney(
                      data.totalPrice -
                        toNumber(currentRole.group.health_credit_amount),
                      userCurrency.rate
                    )
                  : data.totalPrice,
              currency: userCurrency.symbol,
              amount_hc:
                (data.callerTo === 'BUY'
                  ? data.totalPrice -
                    toNumber(currentRole.group.health_credit_amount)
                  : _toHealthCredit(data.totalPrice)
                ) | roundPipe
            }
    }}
  </div>
  <div class="col-span-1 w-full px-8 pb-8 pt-4">
    <a
      [href]="data.stripeInfos.url"
      [target]="this.data.callerTo !== 'SUBSCRIPTION' ? '_blank' : '_self'">
      <app-button-custom
        shape="4"
        [loading]="isLoading"
        [text]="'corporate-campaign.payment-pay-securly-label' | translate"
        [disable]="isLoading"
        (click)="paymentStatusObserver()">
        <div
          *ngIf="isLoading"
          class="w-full flex items-center justify-center h-20">
          <app-spinner class="inline-block ml-4 h-8 w-8"></app-spinner>
        </div>
      </app-button-custom>
    </a>
  </div>
</div>
