import { inject } from '@angular/core';
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { SessionService } from '../services/session.service';
import { ServicesService } from '../services/services.service';
import { Observable } from 'rxjs';
import { GroupType, UserType } from '../enums/enums';

export function loginGuard(): CanActivateFn {
  return (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean => {
    const authService: SessionService = inject(SessionService);
    const services: ServicesService = inject(ServicesService);
    const router: Router = inject(Router);

    let user = authService.getUser();
    let currentRole = services.roleService.getCurrentRole();
    if (authService.isLoggedIn() && currentRole) {
      if (authService.hasRole(GroupType.Hcp)) {
        services.roleService.changeRoleWithGroupType(GroupType.Hcp);
      } else if (authService.hasRole(GroupType.Benefit)) {
        services.roleService.changeRoleWithGroupType(GroupType.Benefit);
      } else if (authService.hasRole(GroupType.Companie)) {
        services.roleService.changeRoleWithGroupType(GroupType.Companie);
      } else if (authService.hasRole(GroupType.Admin)) {
        services.roleService.changeRoleWithGroupType(GroupType.Admin);
      } else if (
        authService.hasRole(GroupType.Kimbo) ||
        user.user_type === UserType.Guest
      ) {
        services.roleService.changeRoleWithGroupType(GroupType.Kimbo);
      } else {
        router.navigate(['/404']);
      }
      return false;
    } else {
      services.customNavigationService.goTo('/login');
      //authService.loginGuest();
      return true;
    }
  };
}
