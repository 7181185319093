import { BuyedpackageStatus, RatingType } from '../enums/enums';
import { Description, Title } from './Common';
import { IConversation } from './Conversation';
import { IGroup } from './Group';
import { IHealthcarepackage } from './Healthcarepackage';
import { IHealthcareProvider } from './HealthcareProvider';
import { MetricQuestions } from './MetricQuestions';
import { Patient } from './Patient';

export interface IBuyedpackage {
  id: number;
  verification_type: string;
  status: BuyedpackageStatus;
  healthcarepackage_id: number;
  healthcareprovider_id: number;
  name: string;
  patient_id: number;
  group_id: number;
  campaign_id: number;
  data: any;
  consentments: any[];
  created_at: string;
  updated_at: string;
  final_invoice: any;
  new_message: boolean;
  deleted_at: any;
  patient_data?: any;
  group?: IGroup;
  campaign?: IBuyedpackage;
  patient: Patient;
  healthcarepackage?: IHealthcarepackage;
  healthcarepackages: IHealthcarepackage[];
  healthcarepackages_group: any[];
  healthcarepackages_hcp: any[];
  healthcareprovider?: IHealthcareProvider;
  health_credit_amount: number;
  money_amount: number;
  last_log?: Log;
  notes: any[];
  files: any[];
  logs: Log[];
  margin: number;
  recommendations: Recommendation[];
  file_amount?: number;
  count?: number;
  metric_questions?: MetricQuestions[];
  metrics?: [];
  conversations: IConversation[];
  total_credit_amount: number;
  cancheckout?: boolean;
  is_on_the_fly?: boolean;
  title?: Title;
  description?: Description;
}

export interface Log {
  id: number;
  status: string;
  buyedpackage_status: string;
  buyedpackage_id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
}

export interface Recommendation {
  healthcarepackage_id: number;
  buyedpackage_id: number;
  contact_id: number;
  source_healthcareprovider_id: number;
  healthcareprovider: IHealthcareProvider;
  target_healthcareprovider_id: number;
  note: string;
  amount: number;
  status: string;
  data: any[];
  updated_at: any;
  created_at: any;
  id: 1;
}

export class RecommendationStore {
  healthcarepackage_id: number;
  target_healthcareprovider_id: number;
  note: string;
  amount: number;
  data: any[];

  constructor(
    healthcarepackage_id: number,
    target_healthcareprovider_id: number,
    note: string,
    amount: number,
    data: any[]
  ) {
    this.healthcarepackage_id = healthcarepackage_id;
    this.target_healthcareprovider_id = target_healthcareprovider_id;
    this.note = note;
    this.amount = amount;
    this.data = data;
  }
}

export class PayRecommendation {
  currencie_id?: number;
  pay_hc_amount?: number;
  wallet_hc_amount?: number;
  method?: string;

  constructor(
    currencie_id?: number,
    pay_hc_amount?: number,
    wallet_hc_amount?: number,
    method?: string
  ) {
    this.currencie_id = currencie_id;
    this.pay_hc_amount = pay_hc_amount;
    this.wallet_hc_amount = wallet_hc_amount;
    this.method = method;
  }
}

export class StoreRatingCSAT {
  type: RatingType;
  rating: number;
  message?: string;

  constructor(type: RatingType, rating: number, message?: string) {
    this.type = type;
    this.rating = rating;
    this.message = message;
  }
}
