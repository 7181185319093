import { Component, Renderer2 } from '@angular/core';
import { AppUpdateService } from 'src/app/core/services/app-update.service';
import { ServicesService } from 'src/app/core/services/services.service';

@Component({
  selector: 'app-corporate-layout',
  templateUrl: './corporate-layout.component.html',
  styleUrls: ['./corporate-layout.component.scss'],
})
export class CorporateLayoutComponent {
  isWhiteLabel!: boolean;
  constructor(
    public AppUpdateService: AppUpdateService,
    public services: ServicesService,
    private renderer: Renderer2
  ) {
    this.isWhiteLabel = !!this.services.sessionService.getWhiteLabel();
    this.setHtmlClass(this.isWhiteLabel ? 'familycare' : 'kimbo');
    this.services.sessionService.updateUserStorage();
    this.services.sessionService.storePushN();
  }

  setHtmlClass(className: string) {
    const htmlElement = this.renderer.selectRootElement('html', true);

    // Remove any previously added custom classes
    this.renderer.removeClass(htmlElement, 'familycare');
    this.renderer.removeClass(htmlElement, 'kimbo');

    // Add the new class
    this.renderer.addClass(htmlElement, className);
  }
}
