<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div *ngIf="isMatchedBreakPoint && variant !== '_MENU_SHAPE_2'">
  <ng-container [ngTemplateOutlet]="hcareCredit"></ng-container>
</div>
<div
  *ngIf="!isMatchedBreakPoint && variant === '_MENU'"
  class="flex items-center h-[56px] {{
    isWhiteLabel ? 'bg-[#024f3b]' : 'bg-corporategreen'
  }}  justify-between relative">
  <div
    *ngIf="isTransitioning"
    class="w-full flex items-center justify-center h-full absolute inset-0">
    <app-spinner class="inline-block h-6 w-6 filter-white"></app-spinner>
  </div>
  <a
    (click)="openWalletModal()"
    class="flex items-center justify-between focus:outline-none focus:shadow-inner-lighter-blue md:text-center relative cursor-pointer w-full pl-5 pr-6">
    <div
      class="w-6 h-6 fill-current"
      [inlineSVG]="'assets/svg/wallet.svg'"></div>
    <a
      class="font-bold text-theme-white flex flex-col items-end justify-center ml-2 md:ml-0 md:mt-1">
      <p *ngIf="!isTransitioning && _wallet" class="text-sm">
        {{
          (currentRoles === groupType.Hcp
            ? _toMoney(_wallet.amount, currency ? currency.rate : 0)
            : _toMoney(_wallet.amount, currency ? currency.rate : 0)
          ) | currency : '' : '' : '1.0-0'
        }}
        {{ currency !== null ? currency.code : '' }}
      </p>
      <p
        *ngIf="!isTransitioning && currentRoles !== groupType.Hcp && _wallet"
        class="text-[10px]">
        {{ _wallet.amount | roundPipe | currency : '' : '' : '1.0-0' }}
        {{ 'kimbo-home.healthcredit-label' | translate }}
      </p>
    </a>
  </a>
</div>

<div
  *ngIf="variant === '_MENU_SHAPE_2'"
  class="flex items-center h-[60px] bg-kimbo-gradient w-full justify-between relative">
  <div
    *ngIf="isTransitioning"
    class="w-full flex items-center justify-center h-full absolute inset-0">
    <app-spinner class="inline-block h-6 w-6 filter-white"></app-spinner>
  </div>
  <a
    (click)="openWalletModal()"
    class="flex items-center justify-between text-theme-white focus:outline-none focus:shadow-inner-lighter-blue md:text-center relative cursor-pointer w-full pl-5 pr-6">
    <i class="ri-wallet-line text-2xl"></i>
    <a
      class="font-bold text-theme-white flex flex-col items-end justify-center ml-2 md:ml-0 md:mt-1">
      <p *ngIf="!isTransitioning && _wallet" class="text-base">
        {{
          (currentRoles === groupType.Hcp
            ? _toMoney(_wallet.amount, currency ? currency.rate : 0)
            : _toMoney(_wallet.amount, currency ? currency.rate : 0)
          ) | currency : '' : '' : '1.0-0'
        }}

        {{ currency !== null ? currency.code : '' }}
      </p>
      <p
        *ngIf="!isTransitioning && currentRoles !== groupType.Hcp && _wallet"
        class="text-[11px]">
        {{ _wallet.amount | roundPipe | currency : '' : '' : '1.0-0' }}
        {{ 'kimbo-home.healthcredit-label' | translate }}
      </p>
    </a>
  </a>
</div>

<ng-template #hcareCredit>
  <div
    (click)="openWalletModal()"
    class="bg-blue
    rounded-[10px]
    pl-8
    pr-4
    py-3
    flex
    items-center
    justify-end
    gap-x-4
    cursor-pointer
    hover:bg-opacity-90
    {{ className }}">
    <div
      class="font-bold text-theme-white flex flex-col items-end justify-center">
      <div
        *ngIf="isTransitioning"
        class="w-full flex items-center justify-center h-full">
        <app-spinner
          class="inline-block ml-4 h-6 w-6 filter-white"></app-spinner>
      </div>
      <p *ngIf="!isTransitioning && _wallet" class="text-lg">
        {{
          currentRoles === 'hcp'
            ? _toMoney(_wallet.amount, currency ? currency.rate : 0)
            : _toMoney(_wallet.amount, currency ? currency.rate : 0)
        }}
        {{ currency !== null ? convertXafXof(currency.code) : '' }}
      </p>
      <p
        *ngIf="!isTransitioning && currentRoles !== 'hcp' && _wallet"
        class="text-[8px]">
        {{ _wallet.amount | roundPipe }}
        {{ 'kimbo-home.healthcredit-label' | translate }}
      </p>
    </div>
    <a
      class="inline-flex items-center justify-center relative font-semibold text-center text-sm tracking-wide py-2  h-8 px-4 rounded-xl cursor-pointer {{
        isTransitioning ? 'text-transparent' : 'text-blue'
      }}  {{
        isTransitioning ? 'opacity-75 select-none pointer-events-none' : ''
      }}  focus:outline-none bg-white rounded-[5px]"
      spinner>
      <span
        *ngIf="!isTransitioning && currentRoles !== groupType.Hcp"
        class="uppercase text-kimbo-blue"
        >{{ 'kimbo-layout.hc-wallet-button' | translate }}</span
      >
      <span
        *ngIf="!isTransitioning && currentRoles === groupType.Hcp"
        class="uppercase"
        >{{ 'hcp-layout.nav-finances-link' | translate }}</span
      >
      <span
        *ngIf="isTransitioning"
        class="absolute flex justify-center items-center inset-0 filter-blue">
        <app-spinner classNames="w-6 h-6 filter-blue"></app-spinner>
      </span>
    </a>
  </div>
</ng-template>
